




















































































import { Component, Inject as VueInject, Mixins, Vue } from 'vue-property-decorator'

import { AbstractModal } from '../../../shared/organisms/AbstractModal'
import { CartShippingAddress } from '../../../../contexts'
import {
  calculateBoundaries,
  calculateDistance,
  calculateZoom,
  defaultCoordinates,
  loadLeaflet,
  locateBasedOnPostCode,
  locateBasedOnQuery,
  locateUser,
  Point,
  TILE_SIZE_256,
  toQueryStreetAddress,
  UserLocation
} from '../../../shared/services/leaflet'
import { DictionaryServiceType, IDictionaryService } from '../../../shared/services/dictionary'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../support'
import { FullscreenLoader } from '../../../shared/molecules/Loader'
import { NavTabsItemProps } from '../../../../dsl/molecules/NavTabs'
import { StructureConfigurable } from '../../../../support/mixins'

import { INPOST_MODAL_COMPONENT_KEY, INPOST_MODAL_DEFAULT_CONFIG } from './InPostModal.config'
import { InPostModalPayload, InPostModalTab, InPostParcel } from './InPostModal.contracts'
import { loadParcels, loadParcelsByName } from './InPostModal.helpers'
import { ParcelDetails } from './partials/ParcelDetails.vue'
import { ParcelListItem } from './partials/ParcelListItem.vue'
import PinIcon from './icons/PinIcon.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 */
@Component<InPostModal>({
  name: 'InPostModal',
  components: {
    FullscreenLoader,
    ParcelDetails,
    ParcelListItem,
    PinIcon
  },

  created (): void {
    this.config = this.getComponentConfig(INPOST_MODAL_COMPONENT_KEY, INPOST_MODAL_DEFAULT_CONFIG)
  },
  mounted () {
    this.setupModal().then(() => this.setupMap())
  }
})
export class InPostModal extends Mixins<AbstractModal<InPostModalPayload>,
  StructureConfigurable>(AbstractModal, StructureConfigurable) {
  @Inject(DictionaryServiceType)
  protected readonly dictionaries!: IDictionaryService

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: defaultProvider<boolean>(false) })
  protected readonly isMobileCallback!: () => boolean

  public activeTab: InPostModalTab = InPostModalTab.Map
  public detailsActive: boolean = false
  protected hoveredIcon = null
  protected hoveredParcel: null | InPostParcel = null
  protected icon = null
  public isMapLoading: boolean = true
  public isUserLocated: boolean = false
  protected isSelectedMarkerPop = false
  protected lat: number = defaultCoordinates.lat
  protected lng: number = defaultCoordinates.lng
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected map: any = null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected mapBounds: any = null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected markers: any = null
  public parcels: InPostParcel[] = []
  protected popIcon = null
  public search: string | null = null
  protected selectedIcon = null
  public selectedParcel: null | InPostParcel = null
  protected selectedParcelID = null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected selectedMarker: any = null
  readonly tabs = InPostModalTab
  protected userLocation: UserLocation | null = null
  protected zoom: number = defaultCoordinates.zoom

  public searchValue: string = ''

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected leaflet: any = null

  /**
   * Current cart shipping address.
   */
  protected get address (): CartShippingAddress {
    if (this.payload.cart.shippingAddress === null) {
      throw new Error(
        '[InPostModal]: Cannot select InPost parcel when shipping address is not defined.'
      )
    }

    return this.payload.cart.shippingAddress
  }

  /**
   * Determine if there's a mobile device in use.
   */
  public get isMobile (): boolean {
    return this.isMobileCallback()
  }

  public get tabOptions (): Pick<NavTabsItemProps, 'id' | 'label'>[] {
    return [
      {
        id: InPostModalTab.Map,
        label: 'Mapa'
      },
      {
        id: InPostModalTab.List,
        label: 'Lista'
      }
    ]
  }

  public get showPaymentOptions (): boolean {
    return this.getConfigProperty('showPaymentOptions')
  }

  public get isSearchByPostcode (): boolean {
    return this.getConfigProperty('isSearchByPostcode')
  }

  /**
   * Found parcels ordered by distance.
   */
  public get searchResults () {
    if (!this.mapBounds || this.userLocation === null) {
      return []
    }

    const location = this.userLocation

    return this.parcels
      .filter(parcel =>
        this.mapBounds.contains({
          lat: parseFloat(parcel.location.latitude),
          lng: parseFloat(parcel.location.longitude)
        })
      )
      .sort((A, B) =>
        calculateDistance(
          location.pos,
          new Point(
            parseFloat(A.location.latitude),
            parseFloat(A.location.longitude)
          )
        ) >=
        calculateDistance(
          location.pos,
          new Point(
            parseFloat(B.location.latitude),
            parseFloat(B.location.longitude)
          )
        )
          ? 1
          : -1
      )
  }

  /**
   * Clear hovered parcel.
   */
  public clearHoveredParcel (): void {
    if (!this.markers) {
      return
    }

    this.map.removeLayer(this.markers)
    this.markers = null
    this.addMarkers()

    if (this.selectedParcel) {
      this.selectedParcelID = this.findParcelPinId(this.selectedParcel)

      if (
        this.selectedParcelID &&
        this.map._layers[`${this.selectedParcelID}`]
      ) {
        this.map._layers[`${this.selectedParcelID}`].setIcon(
          this.selectedIcon
        )
      }
    }

    this.hoveredParcel = null
  }

  /**
   * When user clicked btn back.
   */
  public onMoveBack () {
    this.detailsActive = false

    setTimeout(() => {
      this.selectedParcel = null

      if (this.markers) {
        this.map.removeLayer(this.markers)
        this.markers = null
        this.addMarkers()
      }

      this.selectedParcelID = null
    }, 300)
  }

  /**
   * Select parcel & forward it to receiver.
   */
  public onParcelSelected () {
    if (!this.selectedParcel) {
      return
    }

    this.payload.onSelection(
      this.selectedParcel
    )
  }

  /**
   * Load parcels based on user's interaction.
   */
  public async onSearch (value: string): Promise<void> {
    this.searchValue = value

    this.map.removeLayer(this.markers)

    this.isMapLoading = true
    this.parcels = []

    const locations = this.isSearchByPostcode
      ? await locateBasedOnPostCode(this.searchValue)
      : await locateBasedOnQuery(this.searchValue)
    if (!Array.isArray(locations) || locations.length === 0) {
      await this.loadParcelsByName(
        this.searchValue.toUpperCase()
      )
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const zipCodeLocation: any = locations[0]
      const zipCodePoint = new Point(
        parseFloat(zipCodeLocation.lat),
        parseFloat(zipCodeLocation.lon)
      )

      // Try to fetch the kiosks not further away
      // than 15000 meters from the constructed Point.
      await this.loadParcels(zipCodePoint.lat, zipCodePoint.lng, 20000)
      // Center the map on the closest Parcel.
      await this.flyToClosestParcel(zipCodePoint)
    }

    if (this.parcels.length > 0) {
      this.addMarkers()
    }

    this.isMapLoading = false
  }

  /**
   * Set hovered parcel as active.
   */
  public setHoveredParcel (parcel: InPostParcel): void {
    this.hoveredParcel = this.findParcelPinId(parcel)

    if (this.hoveredParcel && this.map._layers[`${this.hoveredParcel}`]) {
      this.map._layers[`${this.hoveredParcel}`].setIcon(this.hoveredIcon)
    }
  }

  /**
   * Display parcel details at modal.
   */
  public showParcelDetails (parcel: InPostParcel): void {
    this.selectedParcel = parcel

    this.selectedParcelID = this.findParcelPinId(parcel)

    if (this.selectedParcelID && this.map._layers[`${this.selectedParcelID}`]) {
      this.map._layers[`${this.selectedParcelID}`].setIcon(this.selectedIcon)
    }

    const rect = (this.$refs
      .canvas as HTMLDivElement).getBoundingClientRect()
    this.zoom = calculateZoom(
      calculateBoundaries([new Point(parcel.point._lat.value, parcel.point._lng.value)]),
      rect.width,
      rect.height,
      TILE_SIZE_256
    )

    setTimeout(
      () => {
        this.map.flyTo(
          [parcel.point._lat.value, parcel.point._lng.value],
          this.zoom,
          {
            animate: !this.isMobile
          }
        )
      },
      this.isMobile ? 250 : 0
    )

    this.detailsActive = true
  }

  /**
   * Zoom in a Map.
   */
  public zoomIn () {
    this.map.zoomIn()
  }

  /**
   * Zoom out a Map.
   */
  public zoomOut () {
    this.map.zoomOut()
  }

  /**
   * Return parcel PinId.
   */
  protected findParcelPinId (parcel: InPostParcel) {
    const kioskCords = {
      lat: parcel.point.lat,
      lng: parcel.point.lng
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pin: any = Object.values(this.map._layers).find((layer: any) => {
      const lat = layer._latlng ? layer._latlng.lat : null
      const lng = layer._latlng ? layer._latlng.lng : null

      return lat === kioskCords.lat && lng === kioskCords.lng
    })

    if (pin && pin._leaflet_id) {
      return pin?._leaflet_id
    }

    return null
  }

  /**
   * Load InPost parcels based on lat & lng.
   */
  protected async loadParcels (lat: number, lng: number, distance: number = 10000): Promise<void> {
    const parcels: InPostParcel[] = await loadParcels(lat, lng, distance)
    this.processLoadedParcels(parcels)
  }

  /**
   * Load InPost parcels based on name.
   */
  protected async loadParcelsByName (name: string): Promise<void> {
    const parcels: InPostParcel[] = await loadParcelsByName(name)
    if (parcels.length === 0) {
      return
    }

    this.processLoadedParcels(parcels)

    const parcelPoint = parcels[0].location
    this.map.flyTo([parcelPoint.latitude, parcelPoint.longitude], 16, {
      animate: false
    })
  }

  /**
   * Load init parcels, map & set init search values.
   */
  protected async setupMap (): Promise<void> {
    if (window.innerWidth >= 768) {
      this.activeTab = InPostModalTab.List
    }

    await this.loadMap()
  }

  /**
   * Setup initial values of Modal.
   */
  protected async setupModal (): Promise<void> {
    this.search = `${this.address.street.join(' ')
      .replace(',', '')}, ${this.address.postcode} ${this.address.city}`

    this.userLocation = await this.getUserEstimatedLocation()

    await this.loadParcels(this.userLocation.pos.lat, this.userLocation.pos.lng)

    this.searchValue = (this.address.postcode !== this.userLocation.postcode
      ? this.userLocation.postcode : this.address.postcode) ?? ''

    this.isUserLocated = true
  }

  /**
   * Add markers to Map.
   */
  private addMarkers (): void {
    const Leaflet = this.leaflet

    this.markers = Leaflet.markerClusterGroup({
      maxClusterRadius: 25,
      polygonOptions: {
        weight: 1,
        color: 'rgb(43, 52, 61)'
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      iconCreateFunction: function (cluster: any) {
        return Leaflet.divIcon({
          className: 'cluster-marker',
          html:
            '<div class="child-count">' + cluster.getChildCount() + '</div>'
        })
      }
    })

    this.parcels.forEach(parcel => {
      this.createMarker(parcel)
    })

    this.map.addLayer(this.markers)
  }

  /**
   * Create a marker to attach it on a Map.
   */
  private createMarker (parcel: InPostParcel): void {
    if (!this.leaflet || !this.markers) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let marker: any
    const position = parcel.point.toArray()

    if (parcel.type.includes('pop')) {
      marker = this.leaflet.marker(position, {
        icon: this.popIcon,
        custom: true
      })
    } else {
      marker = this.leaflet.marker(position, {
        icon: this.icon,
        custom: true
      })
    }

    marker.addEventListener('click', (e: Event) => {
      this.leaflet.DomEvent.stopPropagation(e)

      const rect = (this.$refs
        .canvas as HTMLDivElement).getBoundingClientRect()

      this.lat = parseFloat(parcel.location.latitude)
      this.lng = parseFloat(parcel.location.longitude)
      this.zoom = calculateZoom(
        calculateBoundaries([new Point(this.lat, this.lng)]),
        rect.width,
        rect.height,
        TILE_SIZE_256
      )

      setTimeout(
        () => {
          this.map.flyTo([this.lat, this.lng], this.zoom, {
            animate: !this.isMobile
          })
        },
        this.isMobile ? 250 : 0
      )

      if (this.selectedMarker && this.isSelectedMarkerPop) {
        this.selectedMarker.setIcon(this.popIcon)
      }

      if (this.selectedMarker && !this.isSelectedMarkerPop) {
        this.selectedMarker.setIcon(this.icon)
      }

      this.selectedMarker = marker
      this.isSelectedMarkerPop = parcel.type.includes('pop')
      this.selectedMarker.setIcon(this.selectedIcon)

      this.showParcelDetails(parcel)
    })

    this.markers.addLayer(marker)
  }

  /**
   * Centers the map on the Parcel closest to the User's location (position).
   */
  private flyToClosestParcel (
    reference: Point,
    zoom?: number
  ): void {
    let distances: number[][] = []

    for (const index in this.parcels) {
      const parcelPoint = new Point(
        parseFloat(this.parcels[index].location.latitude),
        parseFloat(this.parcels[index].location.longitude)
      )

      distances.push([Number(index), calculateDistance(reference, parcelPoint)])
    }

    if (!distances.length) {
      return
    }

    distances = distances.sort((a, b) => (a[1] >= b[1] ? 1 : -1))

    const closestParcel = this.parcels[distances[0][0]]
    const rect = (this.$refs.canvas as HTMLDivElement).getBoundingClientRect()

    this.lat = closestParcel.point.lat
    this.lng = closestParcel.point.lng

    this.zoom = (typeof zoom === 'number' && zoom <= 22 && zoom >= 1)
      ? zoom
      : calculateZoom(
        calculateBoundaries([closestParcel.point]),
        rect.width,
        rect.height,
        TILE_SIZE_256
      )

    this.map.flyTo([this.lat, this.lng], this.zoom, {
      animate: false
    })
  }

  /**
   * Fetch location based on inserted shipping address.
   */
  private async getUserEstimatedLocation (): Promise<UserLocation> {
    const country: string | undefined = this.dictionaries.countries.find(
      c => c.twoLetterAbbreviation === this.address.countryCode
    )?.fullNameEnglish

    return await locateUser({
      country,
      city: this.address.city,
      postcode: this.address.postcode,
      street: toQueryStreetAddress(this.address.street)
    })
  }

  /**
   * Setup all related to map with watch.
   */
  private async loadMap (): Promise<void> {
    this.isMapLoading = true

    const canvas = this.$refs.canvas as HTMLDivElement
    canvas.style.height =
      (this.$refs.mapRoot as Vue).$el.getBoundingClientRect().height + 'px'

    if (!this.leaflet) {
      this.leaflet = await loadLeaflet()
    }

    this.icon = this.leaflet.icon({
      iconUrl: require('./icons/inpost-auto.png'),
      iconSize: [56, 56],
      iconAnchor: [28, 56]
    })

    this.selectedIcon = this.leaflet.icon({
      iconUrl: require('./icons/inpost-selected.png'),
      iconSize: [56, 56],
      iconAnchor: [28, 56]
    })

    this.popIcon = this.leaflet.icon({
      iconUrl: require('./icons/inpost-pop.png'),
      iconSize: [42, 56],
      iconAnchor: [21, 56]
    })

    this.hoveredIcon = this.leaflet.icon({
      iconUrl: require('./icons/inpost-selected.png'),
      iconSize: [66, 66],
      iconAnchor: [33, 66]
    })

    this.map = this.leaflet.map('map-canvas', {
      edgeBufferTiles: 5,
      zoomControl: false
    }).setView([this.lat, this.lng], this.zoom)

    this.map.on('moveend', () => {
      this.mapBounds = this.map.getBounds()
      if (!this.mapBounds) {
        return
      }

      this.lat = this.mapBounds.getCenter().lat
      this.lng = this.mapBounds.getCenter().lng

      this.loadParcels(this.lat, this.lng)
    })

    this.leaflet.tileLayer(
      'https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png',
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        detectRetina: true,
        subdomains: 'abcd',
        maxZoom: 19
      }
    ).addTo(this.map)

    this.addMarkers()

    if (this.userLocation) {
      await this.flyToClosestParcel(this.userLocation.pos)
    }

    this.isMapLoading = false
  }

  /**
   * Process loaded parcels against already existed.
   */
  private processLoadedParcels (parcels: InPostParcel[]): void {
    for (const p of parcels) {
      if (this.parcels.find(e => e.name === p.name)) {
        continue
      }

      const parcel = {
        ...p,
        point: new Point(
          parseFloat(p.location.latitude),
          parseFloat(p.location.longitude)
        )
      }

      this.createMarker(parcel)
      this.parcels.push(parcel)
    }
  }
}

export default InPostModal
